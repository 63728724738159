<template>
  <TableTemplate
    :table-name="tableName"
    :table-columns="tableColumns"
    :use-language-selector="useLanguageSelector"
    :use-import="useImport"
  />
</template>

<script>
import TableTemplate from './TableTemplate';

export default {
  components: {
    TableTemplate
  },
  data() {
    return {
      tableName: 'module-main-detail',
      tableColumns: [
        {
          id: 'id',
          name: 'ID',
          allowEdit: false
        },
        {
          id: 'sale_module_id',
          name: 'Sale Module',
          lookup: {
            table: 'sales-module',
            displayExpr: 'name',
            valueExpr: 'id'
          }
        },
        {
          id: 'module_main_id',
          name: 'Module Name',
          lookup: {
            table: 'module-main',
            displayExpr: 'description',
            valueExpr: 'id'
          }
        },{
          id: 'is_active',
          name: 'Is Active',
          dataType : 'boolean'
        }
      ],
      useLanguageSelector: false,
      useImport: false
    };
  }
};
</script>
